import { Store } from "vuex" ;
import { BigBigWork, getDownloadImageUrl, ResultItem, searchDownloadImg, StoreDownload, waitTime } from "bbw-common" ;
import DialogVip from '../components/DialogVip/DialogVip.vue' ;
import Vue, { Component } from "vue" ;
import type Router from "vue-router" ;

/** 创建vip弹框 */
export const createDialogVip = ( () => {
    let instanceDialogVip = null ;
    return async function ( options:{
        store:Store<any>,
        customTitle?: string,
        source?: string,
        fromType?: number,
    } ) {
        if ( !instanceDialogVip ) {
            // const dialogVip = await import ( `../../../../node_modules/bbw-components/modules/browser/packages/bbw-components/src/components/DialogVip` ) ;
            const compOptions = {
                ...DialogVip,
                props: {
                    ...( DialogVip as any ).props,
                    isShow: {
                        type: Boolean,
                        default: false
                    },
                    // 自定义标题
                    customTitle: {
                        type: String,
                        default: options.customTitle
                    },
                    source: {
                        type: String,
                        default: options.source || `其他`
                    },
                    fromType: {
                        type: Number,
                        default: options.fromType || 9,
                        required: false
                    }
                }
            } as any ;
            const InstanceDialogVipCom = Vue.extend ( compOptions ) ;
            instanceDialogVip = new InstanceDialogVipCom () ;
            instanceDialogVip.$store = options.store ;
            const el = document.createElement ( `div` ) ;
            instanceDialogVip.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            instanceDialogVip.$nextTick ().then ( res => {
                instanceDialogVip.isShow = true ;
            } ) ;
        } else {
            instanceDialogVip.customTitle = options.customTitle ;
            instanceDialogVip.isShow = true ;
            instanceDialogVip.instanceShow = true ;
            instanceDialogVip.source = options.source ;
            instanceDialogVip.fromType = options.fromType ;
        }
    } ;
} ) () ;

/** 大作下载图片 */
export const toDownloadImg = async ( item: ResultItem, store: Store<any>, fromText: string, fromType: number, resource?: { default: string; type: StringConstructor; required: boolean } ) => {
    try {
        const msg = Object.assign ( { from_text: fromText, from_type: fromType },
            {
                u_id: store.state.UserInfo.id,
                user_type: store.state.UserInfo.userType,
                action_source: 1
            } ) ;
        // 注册下载模块
        StoreDownload.CreateUserDownload ( store ) ;
        // @ts-ignore
        if ( !store.getters[`UserInfo/isVip`] && !item.isFreeDownload ) {
            // 获取今日可下载数
            await store.dispatch ( `UserDownload/FETCH_CAN_DOWNLOAD` ) ;
            if ( !store.getters[`UserDownload/canDownload`] ) {
                /** 打开vip弹框 */
                createDialogVip ( { store, source: fromText } ) ;
                BigBigWork.emit ( `Analysis`, { type: `openPay`, data: msg } ) ;
                return ;
            }
        }
        // 默认加速 false
        let userProxy = false ;
        if ( item.expandimage ) {
            userProxy = false ;
        }
        const url = await getDownloadImageUrl ( item, null, userProxy ) ;
        searchDownloadImg ( url ) ;
        // @ts-ignore
        if ( !store.getters[`UserInfo/isVip`] && !item.isFreeDownload ) {
            await store.dispatch ( `UserDownload/FETCH_ADD_TODAY_DOWNLOAD` ) ;
        }
    } catch ( e ) {
    }
} ;
/** 对话框事件 */
export type DialogCloseEvent = {
    type: string,
    data?: any
}
/** 显示对话框
 * @description 显示对话框, 通过动态加载组件的方式, 传入组件路径, 以及组件的propsData, 返回对话框的关闭事件
 * @param dialogComp 对话框组件
 * @param opts 配置
 * @returns Promise<DialogCloseEvent>
 */
export const showDialogAsync = async (
    dialogComp: Component<any>,
    opts?: {
        propsData?: {[key in string]: any}, // 对话框的propsData
        aniDuration?: number, // 动画时长
        store?: Store<any>, // vuex store
        router?: Router, // vue-router
        compName?: string, // 组件名称
        closeEventNames?: string[], // 关闭事件名称
        closedEventNames?: string[], // 关闭事件名称
        closeCallback?: ( resolve:( value:DialogCloseEvent )=>void, event:DialogCloseEvent )=>void, // 关闭回调
        openCallback?: ( event:DialogCloseEvent )=>void, // 打开回调
    }
) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise ( async ( resolve, reject ) => {
        const {
            propsData = {},
            store = null,
            closeEventNames = [`close`],
            closedEventNames = [`closed`],
            compName = `Dialog-${new Date ().getTime ()}`,
            closeCallback = null,
            openCallback = null
        } = opts ?? {} ;
        // @ts-ignore
        const Dialog = Vue.extend ( dialogComp ) ;
        const instance = new Dialog ( {
            propsData: {
                show: true,
                ...propsData
            },
            store: store,
            name: compName
        } ) ;
        // 关闭事件, 通过closeEventNames来监听
        const handleClose = async ( event:DialogCloseEvent ) => {
            if ( closeCallback ) {
                return closeCallback ( resolve, event ) ;
            } else {
                return resolve ( event ) ;
            }
        } ;
        // 监听关闭事件
        for ( const closeEventName of closeEventNames ) {
            instance.$on ( closeEventName, ( e ) => {
                handleClose ( {
                    type: closeEventName,
                    data: e
                } ) ;
            } ) ;
        }
        // 监听动画关闭事件, 销毁实例
        const handleClosed = () => {
            document.body.removeChild ( instance.$el ) ;
            instance.$destroy () ;
        }
        // 监听关闭事件
        for ( const closedEventName of closedEventNames ) {
            instance.$on ( closedEventName, handleClosed ) ;
        }
        instance.$mount () ;
        document.body.appendChild ( instance.$el ) ;
        // 打开回调
        if ( openCallback ) {
            openCallback ( { type: `open`, data: instance } ) ;
        }
    } ) ;
} ;
