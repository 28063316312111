<template>
  <div :class="$style.recommendedWrapper">
    <div v-for="(word,index) in recommendedWords" :key="index" :class="[$style.recommendedWord, isActiveItem('words', index) ? $style.hover:'']">
      <a :href="`/tupian?kw=${word}`" @mousedown.prevent.capture.stop="handleClick(word)"
         @mouseenter="onItemEnter(index)" @mouseleave="onItemLeave(index)" @click.prevent.stop>
        <span :class="$style.normal">{{recommendedLeft(searchWord,word)}}</span><span :class="$style.bold">{{recommendedRight(searchWord,word)}}</span>
      </a>
    </div>
    <div :class="$style.recommendBoards"
         :style="{paddingTop: recommendedWords.length ? '8px':''}"
         v-show="searchWord && recommendBoardsList.length > 0">
      <div :class="[$style.recommendTitle,'font-regular']">大作灵感图片集推荐</div>
      <div v-for="(item,index) in recommendBoardsList" :key="item.uuid"
           :class="[$style.recommendBoardsItem,isActiveItem('boards', index) ? $style.hover : '']"
           @mousedown.capture.stop="goToBoard(item)">
        <div :class="$style.BoardsImg">
          <img :src="toRelativeProtocol(item.cover1)+'?x-oss-process=style/pc_100_jpg'" alt="">
        </div>
        <div :class="$style.BoardsInfo">
          <div :class="[$style.BoardsInfoTitle,'font-medium']">{{ item.board_title }}</div>
          <div :class="[$style.BoardsInfoDesc,'font-regular']">{{ item.username }}</div>
        </div>
      </div>
      <div :class="[$style.moreBoards,'font-medium',isActiveItem('moreBoards', 0) ? $style.hover : '']"
           @mousedown.capture.stop="goToMoreBoards({ more: keyword })">
        查看更多“{{ keyword }}”大作图片集
      </div>
    </div>
  </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import axios from "axios";
    import {ServerRecommendWord, ServerWww, toRelativeProtocol } from "bbw-common";
    /** 搜索词推荐 */
    export default Vue.extend({
        props:{
            /** 搜索关键词(加粗对比用) */
            searchWord:{
                type:String,
                required:true
            },
            /** 推荐词列表 */
            recommendedWords:{
                type: Array,
                required:false,
                default:false
            },
            recommendBoardsList:{
                type: Array,
                required:false,
                default:()=>{
                  return []
                }
            },
            'keyword': {
              type: String,
              default: '',
              required: false
            }
        },
        mounted() {
            this.onMounted();
        },
        destroyed() {
            this.onDestroyed();
        },
        data(){
            return {
              moreBoards:{
                more:``
              },
              toRelativeProtocol
            }
        },
        methods:{
            onMounted(){
                document.body.addEventListener("keydown", this.onKeydown);
            },
            onDestroyed(){
                document.body.removeEventListener("keydown", this.onKeydown);
            },
            isActiveItem(type,index){
              let dropDownIndex = this.$store.state.dropDownIndex;
              if( dropDownIndex == null ) return;
              if(type === 'words'){
                const wordsIndex = index;
                console.log('words',wordsIndex)
                return dropDownIndex === wordsIndex;
              }else if(type === 'boards'){
                const boardsIndex = this.recommendedWords.length + index;
                console.log('boards',boardsIndex)
                return dropDownIndex === boardsIndex;
              }else if(type === 'moreBoards'){
                const moreBoards =this.recommendedWords.length + this.recommendBoardsList.length + index;
                console.log('moreBoards',moreBoards)
                return dropDownIndex === moreBoards;
              }
            },
            /** 键盘事件处理 */
            onKeydown(e){
                console.log(e.keyCode)
                if(e.keyCode === 40){
                    //向下
                    if (this.$store.state.dropDownIndex == null){
                      this.$store.commit('updateDropDownIndex',0);
                    }else{
                      const index = (this.$store.state.dropDownIndex + 1) % this.totalItemLength;
                      this.$store.commit('updateDropDownIndex',index);
                    }
                    const words = this.getKeywords(this.$store.state.dropDownIndex);
                    this.$store.commit('updateDropDownValue',words);
                    this.$emit(`changeKeyword`, words);
                }else if( e.keyCode === 38 ){
                    //向上
                    if( this.$store.state.dropDownIndex == null ){
                      const index = this.totalItemLength -1;
                      this.$store.commit('updateDropDownIndex',index);
                    }else{
                      const index = (this.$store.state.dropDownIndex - 1) % this.totalItemLength;
                      console.log(' %c 向上', 'font-size:20px; color:red', index);
                      if(index < 0){
                        const currentIndex = this.totalItemLength + index;
                        this.$store.commit('updateDropDownIndex',currentIndex);
                      }else{
                        this.$store.commit('updateDropDownIndex',index);
                      }
                      const words = this.getKeywords(this.$store.state.dropDownIndex);
                      this.$store.commit('updateDropDownValue',words);
                      this.$emit(`changeKeyword`, words);
                    }
                }else if( e.keyCode === 13 ){
                  const index = this.$store.state.dropDownIndex;
                  if(index !== null){
                    const words = this.getKeywords(index);
                    this.$emit('onKeywordChange',words);
                  }
                }
            },
            /** 鼠标进入一个补充词时调用 */
            onItemEnter(index){
              // this.$store.commit('updateDropDownIndex',index);
            },
            getKeywords(index){
              console.log('1111111111',index)
              const wordsLength = this.recommendedWords.length;
              const boardsLenght = this.recommendBoardsList.length;
              console.log('22222222',wordsLength,boardsLenght);
              if(index !== null && index >= wordsLength && index < wordsLength + boardsLenght){
                const currentIdx = index - wordsLength;
                return this.recommendBoardsList[currentIdx];
              }else if(index !== null && index >= 0 && index < wordsLength){
                return this.recommendedWords[index]
              }else {
                this.moreBoards.more = this.searchWord;
                return this.moreBoards
              }
            },
            /*handleWrapper(){
              if(this.recommendedWords.length || this.recommendBoardsList.length){
                return;
              }
            },*/
            onItemLeave(){},
            handleClick(word:string){
                this.$emit(`onKeywordChange`, word);
                /*this.$router.push({path:'/tupian', query:{kw:word}} )
                this.$emit("disableSearch");*/
            },
            /** 推荐词不加粗部分 */
            recommendedLeft(keyword:string, recommendWord:string){
                const index = keyword.length;
                return  recommendWord.slice(0,index);
            },
            /** 推荐词加粗部分 */
            recommendedRight(keyword:string, recommendWord:string){
                const index = keyword.length;
                return  recommendWord.slice(index);
            },
            /* 图片集 */
            goToBoard(words){
              this.$emit(`onKeywordChange`, words);
            },
            goToMoreBoards(more){
              this.$emit(`onKeywordChange`, more);
            }
        },
        computed:{
          /** 下拉总条目数量(推荐词+图片集+更多图片集) */
          totalItemLength(){
            return this.recommendedWords.length
                + this.recommendBoardsList.length
                + (this.recommendBoardsList.length ? 1 : 0);
          }
        },
        watch:{
            'searchWord': function(newV,oldV){
                if(newV !== oldV){
                  this.$store.commit('updateDropDownIndex', null);
                }
            }
        }
    })
</script>

<style lang=scss module src="./SearchRecommended.scss"></style>
